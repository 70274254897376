<template>
  <v-row>
    <!-- Jangan dikasih margin, nanti bisa jadi ga fixed navbar nya -->
    <v-col
      class="py-0"
      cols="12"
      no-gutters
      v-for="(item, index) in item"
      :key="index"
    >
      <CardBuyTicket
        :price="item.price"
        :paymentStatus="item.inv_payment_status"
        :invoiceID="item.invoice_number"
        :invoiceNO="item.invoice_number"
        :wallet="item.payment_channel_name"
        :status="item.inv_payment_status"
        :time="item.success_time"
        :issuer_fee="item.issuer_service_fee"
        :amount_issuer_fee="item.convertedPrice"
        :clickInformation="clickRouteInformation"
        :routeInformation="item.fare_detail.route_information"
        :payment_token="item.payment_token"
        :generate_ticket="item.generate_ticket"
        :currency_code="item.selectedCurrency"
        :fare_name="item.fare_name"
        :details="item"
        v-model="item.cart_qty"
        @buy="togglebuy($event, index)"
      />
    </v-col>
  </v-row>
</template>

<script>
import CardBuyTicket from "@/components/CardTransactionHistoryV2.vue";
// import { component } from 'vue/types/umd';

export default {
  props: ["item", "clickRouteInformation", "togglebuy"],
  components: { CardBuyTicket },
};
</script>

<style scoped>
.col {
}
</style>