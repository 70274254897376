<template>
  <v-row align="center" justify="center">
    <v-col cols="12" class="content pa-0 ma-0">
      <v-card elevation="1" class="card-content my-2 mx-4 pa-2">
        <v-row class="py-0 my-0">
          <v-col class="transc pb-0">
            <b>{{ $t("TransactionHistory") }}</b>
          </v-col>
          <v-col class="farename pb-0">
            <span class="caption">{{ fare_name }}</span>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="ml-3 pl-5">
            <v-row class="totalpay">
              <b class="black--text">{{ $t("TotalPayment") }}</b></v-row
            >
            <v-row class="inv-style">
              <p>{{ invoiceNO }}</p>
            </v-row>
            <v-row class="caption">
              {{ $t("Time") }} : {{ time }}<br />
              {{ $t("Status") }} : {{ $t(status_) }}
            </v-row>
          </v-col>

          <v-col>
            <p class="num-135000-v-nd">
              <!-- {{ Intl.NumberFormat().format(amount_issuer_fee) }} VNĐ -->
              {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: currency_code }).format(amount_issuer_fee) }} 
            </p>
          </v-col>
        </v-row>

        <v-row class="py-2">
          <img
            alt=""
            class="divider"
            :src="require('@/assets/buy-ticket/vektor.svg')"
          />
          <!--     <v-divider class=" divider mx-2"></v-divider> -->
        </v-row>
        <v-row align="center">
          <v-col cols="6" class="py-1 pl-5">
            <v-row class="paymethod px-3" v-if="wallet_ != ''"
              ><b>{{ $t("titlePaymentMethod") }}</b></v-row
            >
            <v-row class="px-0 mx-0" align="center" v-if="wallet_ != ''">
              <v-col
                cols="2"
                class="pt-0 pb-0 pr-0 pl-0"
                v-if="details.payment_channel_icon != ''"
              >
                <v-img :src="details.payment_channel_icon"> </v-img>
              </v-col>

              <v-col class="pl-1 py-0" v-else>
                <p class="caption">{{ wallet_ === "" ? "" : wallet_ }}</p></v-col
              >
            </v-row>
            <div class="pt-3" v-if="status_ == 'Waitingforpayment' || status_ == 'Pending'">
              <v-btn
                @click="CheckStatus(invoiceNO)"
                small
                rounded
                width="100px"
                color="transparent shadow"
              >
                <span class="text-green capitalize">{{ $t("CheckStatus") }}</span>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="6" class="d-flex justify-end pl-5">
            <v-btn
              v-if="$partnerACL.isShowButton('detail')"
              @click="clickDetail()"
              small
              rounded
              outlined
              width="100px"
              :color="$partnerACL.getButtonColor()"
            >
              <span class="text-green capitalize" :style="`color:${$partnerACL.getButtonColor()}`">{{ $t("Details") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// import moment from "moment";
export default {
  name: "CardRecentTrip",
  props: [
    "price",
    "paymentStatus",
    "invoiceID",
    "invoiceNO",
    "clickInformation",
    "routeInformation",
    "wallet",
    "status",
    "time",
    "trxID",
    "amount_issuer_fee",
    "payment_token",
    "currency_code",
    "fare_name",
    "details"
  ],
  data: function () {
    return {
      wallet_: this.wallet,
      status_: this.status
      // Uuid_: this.TicketUUID.replace(/-/g, '').substring(1, 17).toUpperCase(),
      // Date_ : moment(String(this.DateTrip)).format('DD MMMM YYYY hh:mm A')
    };
  },
  methods: {
    clickDetail() {

      const detailButton = this.$partnerACL.getMetaButton('detail');
      if (detailButton) {
        if (detailButton.menu_type == "wsc_menu") {
          this.$router.push({
            name: detailButton.redirect_name,
            params: {
              // invoiceNO: invoices,
              details: this.details
            },
          });
        } else {
          window.location = detailButton.redirect_path;
        }
      } else {
        this.$router.push({
          name: "DetailHistory",
          params: {
            // invoiceNO: invoices,
            details: this.details
          },
        });
      }
    },
    CheckStatus() {

      const payload = {
        transaction_id: this.details.trx_id
      }
      this.$store.dispatch("orchestrator/transactionDetails", payload)
        .then((response) => {
          const status = String(response.data.status).toLocaleLowerCase()

          this.wallet_ = "momo"
          switch (status) {
            case "paid": 
              this.status_ = "Success";
              break
            case "pending":
              this.status_ = "Pending"
              break
            case "failed":
              this.status_ = "Failed"
              break
            case "expired":
              this.status_ = "Expired";
              break
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card.theme--light.v-sheet {
  // position: absolute;
  // border: 0.5px solid #c5c5c5;
  overflow: hidden;
}

.v-application .elevation-1 {
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.col-kanan {
  height: 100%;
}

.v-application p {
  margin-bottom: 0px;
}

.col {
  flex: 1;
}

.card-content {
  // width: 90%;
  border: 1px solid rgba(72, 158, 75, 1);
  margin: 0;
  border-radius: 10px;
  max-height: 25%;
}

.content {
  margin-bottom: -12px;
}

.transc {
  font-size: 12px;
  color: rgba(75, 177, 78, 1);
  text-align: left;
}

.farename {
  font-size: 14px;
  text-align: right;
}

.totalpay {
  font-size: 14px;
  text-align: left;
}

.inv-style {
  font-style: italic;
  font-size: 10px;
  color: rgba(75, 177, 78, 1);
  text-align: left;
}

.num-135000-v-nd {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  color: rgba(242, 153, 0, 1);
  text-align: right;
}

.divider {
  width: 100%;
}

.paymethod {
  font-size: 10px;
  color: rgba(75, 177, 78, 1);
  text-align: left;
}

.text-green {
  color: #45a648;
}

.shadow-card {
  border: 1px solid #4bb14e;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.shadow {
  box-shadow: 0px 3px 1px -10px rgb(0 0 0 / 50%),
    0px 2px 2px 0px rgb(0 0 0 / 5%), 0px 1px 5px 0px rgb(0 0 0 / 2%) !important;
}

.capitalize {
  text-transform: capitalize;
}

.btn-overflow {
  max-width: inherit;
  // position: relative;
  overflow: overlay;
}

.transparent {
  background-color: transparent !important;
  border: 1px solid #4bb14e !important;
}
</style>