<template>
  <v-container fluid class="home" v-if="loadComponent">
    <notification-popup
      :dialog="notifSuccess"
      :contentMsg="$t('notifTicketSuccessAdd')"
      :headerMsg="$t('Success')"
      :nameBtnNo="$t('ViewAnotherTicket')"
      :nameBtnYes="$t('ViewCart&CheckOut')"
      colorIcon="success"
      typeModal="dialog"
      Icon="mdi-check-circle"
      :outlinedBorderBtnNo="false"
      :onHandlerYes="buttonOke"
      :onHandlerNo="buttonNo"
    />

    <notification-popup
      :dialog="notifFailed"
      :contentMsg="$t('notifTicketFailedAdd')"
      :headerMsg="$t('failed')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('labelYes')"
      colorIcon="danger"
      typeModal="transaction"
      Icon="mdi-close-circle"
      :onHandlerYes="buttonOkeFailed"
    />

    <RouteInformation
      :routeTitle="itemRoute.fare_name"
      :keyItem="itemRoute.fare_id"
      :titleheadlineCard="itemRoute.orig_loc"
      :origin="itemRoute.original_loc"
      :ToOrig="itemRoute.route_loc"
      :destination="itemRoute.destination_loc"
      :ToDest="itemRoute.route_loc"
      :dialog="routeInformationDialog"
      :orig="itemRoute.orig_loc"
      :dest="itemRoute.dest_loc"
    />
    
    <v-container v-if="userRole.toUpperCase().includes('BLACKLIST')">
      <div>
        <v-row
          justify="center"
          class="null-item"
        >
          <v-col class="d-flex justify-center pb-0" cols="10">
            <v-img
              class="image"
              :src="require('../../../assets/bus-homepage.svg')"
            ></v-img>
          </v-col>
          <v-col class="d-flex justify-center py-0 mt-4" cols="12">
            <h2 class="text-center">{{ $t("SuspendWarning") }}</h2>
          </v-col>
        
        </v-row>
      </div>
    </v-container>
    <v-container v-else>
      <ListofTicket
        :item="item"
        :clickRouteInformation="clickRouteInformation"
        :togglebuy="togglebuy"
      />
      <v-row
        v-if="((item.length === 0 && isLoading == false) || !isOnline)"
        justify="center"
        class="null-item"
      >
        <v-col class="d-flex justify-center pb-0" cols="10">
          <v-img
            class="image"
            :src="require('@/assets/icon/no-transaction.svg')"
          ></v-img>
        </v-col>
        <v-col class="d-flex justify-center py-0" cols="12">
          <h2 class="notfication">{{ $t("NoTransaction") }}</h2>
        </v-col>
        <v-col class="d-flex justify-center mt-3">
          <v-btn
            v-if="$partnerACL.isShowButton('purchase')"
            :color="$partnerACL.getButtonColor()"
            rounded
            width="80%"
            class="capitalize body-1"
            @click="clickPurchase()"
          >
            {{ $t("PurchaseTicket") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-3" v-if="eof && hasScroll">
        <b>{{ $t("Eof") }}</b>
      </v-row>
      <div v-if="isLoading" justify="center" class="null-item">
        <v-progress-circular indeterminate color="primary" class="bottom" />
      </div>
    </v-container>

    <!-- <v-footer fixed app width="auto" color="white" elevation="3">
      <v-container>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" align-self="center">
            <v-btn
              rounded
              block
              class="text-capitalize"
              color="primary"
              :disabled="eof"
              @click="addToCart(item)"
            >
              {{ $t("AddToCart") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer> -->
  </v-container>
</template>

<script>
import store from "@/store";
import NotificationPopup from "@/components/NotificationPopup.vue";
// import CardBuyTicket from "@/components/CardBuyTicket.vue";
// import RouteInformation from "@/components/TransactionHistoryDetail.vue";
import RouteInformation from "@/components/RouteInformation.vue";
import ListofTicket from "@/components/ListofTransactionHistory.vue";
import moment from "moment";
export default {
  name: "TransactionHistory",
  components: { RouteInformation, NotificationPopup, ListofTicket },

  data() {
    return {
      searchticket: "",
      bottom: false,
      routeInformationDialog: false,
      notifSuccess: false,
      notifFailed: false,
      selected: [],
      item: [],
      page: 0,
      limit: 20,
      lastPage: 2,
      buttonDisabled: false,
      customerID: store.state.auth.userData.customerID,
      isLoading: false,
      eof: false,
      loadComponent: false,
      hasScroll: false,
      isOnline: navigator.onLine
    };
  },
  computed: {
    itemRoute() {
      return this.$store.state.moduleUi.routeInformation;
    },
    userRole() {
      return this.$store.state.auth.user_role;
    }
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        this.addData();
      }
    },
    itemRoute() {
      if (this.itemRoute == "") {
        this.routeInformationDialog = false;
      } else {
        this.routeInformationDialog = true;
      }
    },
    item: {
      handler: function () {
        if (this.item.filter((item) => item.is_buy == true).length > 0) {
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;
        }
        // console.log(this.item);

        // console.log("item ", data.length);
      },
      deep: true,
    },
    buttonDisabled() {
      // console.log(this.item);
      let data = this.item.filter((item) => item.is_buy == true);
      if (data.length < 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    convertPriceByCurrency(price, currency) {
      const conversionRates = this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.conversionRates : null;
      const priceResult = price * conversionRates[currency];
      return priceResult;
    },
    bottomVisible() {
      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight;
      return bottomOfPage || pageHeight < visible;
    },
    refreshCart() {
      const payload = {
        customerID: this.customerID,
      };
      this.$store
        .dispatch("purchaseticket/getQtyCart", payload)
        .catch((err) => {
          console.log(err);
        });
      this.item = this.item.map((el) => {
        var o = Object.assign({}, el);
        o.is_buy = false;
        return o;
      });
      // console.log(this.item);
    },
    togglebuy(value, index) {
      // console.log("value:", value);
      // console.log("item:", item);
      // console.log("index:", index);
      this.item[index].is_buy = value;
    },
    clickRouteInformation() { },
    addToCart(items) {
      this.selected = [];
      this.selected = items.filter((items) => items.is_buy == true);

      const payload = {
        customerID: this.customerID,
        item: this.selected,
      };
      //  console.log("payload:",payload)
      this.$store
        .dispatch("purchaseticket/addtocart", payload)
        .then((response) => {
          this.item.cart_qty = 0;
          if (response.status) {
            this.notifSuccess = true;
          } else {
            this.notifFailed = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    buttonOke() {
      this.notifSuccess = false;
      this.item.cart_qty = 0;
      if (this.notifSuccess == false) this.$router.push({ name: "Cart" });
    },
    buttonNo() {
      this.notifSuccess = false;
      this.refreshCart();
      // if (this.notifSuccess == false) this.$router.push({ name: "Cart" });
    },
    buttonOkeFailed() {
      this.notifFailed = false;
      // if (this.notifFailed == false) this.$router.push({ name: "PaymentMethodAdd" });
    },
    toCartPage() {
      this.$router.push({ name: "Cart" });
    },
    async addData() {
      this.isLoading = true;
      this.page = this.page + 1;
      var payload = {
        page: this.page,
        limit: this.limit,
      };
      if (this.page <= this.lastPage) {
        await this.$store
          .dispatch("purchaseticket/getTransactionHistory", payload)
          .then((response) => {
            this.isLoading = false;
            this.loadComponent = true
            for (let data of response.data) {
              if (data.payment_status == "paid") {
                data.inv_payment_status = "Success";
              } else if (data.payment_status == "pending") {
                data.inv_payment_status = "Pending";
              } else if (data.payment_status == "fail") {
                data.inv_payment_status = "Failed";
              } else if (data.payment_status == "waitingpayment") {
                data.inv_payment_status = "Waitingforpayment";
              } else if (data.payment_status == "cancel" || data.payment_status == "canceled") {
                data.inv_payment_status = "Cancel";
              } else if (data.payment_status == "expired") {
                data.inv_payment_status = "Expired";
              }
              data.fare_name = data.fare_detail.item_name
              data.convertedPrice = this.convertPriceByCurrency(data.price_after_discount, data.currency_code)
              // data.convertedPrice = this.convertPriceByCurrency(data.price, data.currency_code)
              data.selectedCurrency = this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.code : ""

              data.created_at = moment
                .utc(data.created_at)
                .format("DD-MM-YYYY HH:mm");
              if (data.inv_payment_status == 'Success' && data.generate_ticket == false) {
                data.inv_payment_status = "Failed";
              }
              if (data.inv_payment_status == 'Success') {
                data.success_time = moment.utc(data.payment_success_time).format("DD-MM-YYYY HH:mm")
              }
              this.item.push(data);
            }
            this.lastPage = response.lastPage;
            if (response.page == this.lastPage) {
              this.eof = true;
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.loadComponent = true
            console.log(err);
          });
      }

      this.isLoading = false;
    },
    clickPurchase() {
      const purchaseButton = this.$partnerACL.getMetaButton('purchase');
      if (purchaseButton) {
        if (purchaseButton.menu_type == "wsc_menu") {
          this.$router.push({
            name: purchaseButton.redirect_name,
          });
        } else {
          window.location = purchaseButton.redirect_path;
        }
      } else {
        this.$router.push({
          name: "Ticket",
        });
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", () => {
      this.bottom = this.bottomVisible();
    });
    window.removeEventListener("touchmove", () => {
      this.bottom = this.bottomVisible();
    });
  },
  async created() {
    window.addEventListener("scroll", () => {
      this.bottom = this.bottomVisible();
    });

    window.addEventListener("touchmove", () => {
      this.bottom = this.bottomVisible();
    });

    const payloadCurrency = {
      currencyCode: localStorage.getItem('currency') || "IDR",
    };
    await this.$store.dispatch("purchaseticket/getCurrencyConversionRate", payloadCurrency)
  },
  mounted() {
    // console.log("As", this.selected);
    window.addEventListener("scroll", () => {
      // console.log("here but why?")
      // this.addData()
      this.hasScroll = true
    });
    this.addData();
  },
};
</script>
<style lang="scss" scoped>
.null-item {
  position: absolute;
  top: 46%;
  right: 50%;
  transform: translate(46%, -50%);
}

.capitalize {
  text-transform: capitalize;
}

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .home {
    background: #f5fff7;
  }

  .class-search-ticket {
    background: #f5fff7;
    margin-bottom: -10px;
  }

  .grid {
    display: grid;
  }

  .search-ticket {
    width: 100%;
    padding-left: 30%;
  }

  .footer {
    margin-top: 5%;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    text-align: center;
    background: #ffffff;
    mix-blend-mode: normal;
    /* Field */
    box-shadow: 0px 1px 10px 0px;
  }

  .text-center {
    align-content: center;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .home {
    background: #f5fff7;
  }

  .class-search-ticket {
    margin: 10px;
    background: #fdfdfd;
    margin-bottom: -10% !important;
  }

  .grid {
    display: grid;
  }

  .search-ticket {
    width: 100%;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: #ffffff;
    mix-blend-mode: normal;
    /* Field */

    box-shadow: 0px 1px 10px 0px;
  }

  .text-center {
    align-content: center;
  }
}
</style>